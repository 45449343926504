import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';

import Close from './Close';
import Footer from './Footer';
import Header from './Header';

import ReactGA from 'react-ga4';
import Carousel from "react-multi-carousel";
import { ReactCompareSlider, ReactCompareSliderImage } from 'react-compare-slider';
import { motion, AnimatePresence } from "framer-motion";

import { PiCaretLeftBold } from "react-icons/pi";
import { PiCaretRightBold } from "react-icons/pi";
import { PiGridFourFill } from "react-icons/pi";

export default class PortfolioStudy extends Component {

    Capitalize(str){
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

  render() {
    let resumeData = this.props.resumeData;
    
    
    const url = window.location.href;
    const pieces = url.split("/");
    const page = pieces[pieces.length - 1];
    const section = pieces[pieces.length - 2];
      
    //let prevProjectIndex = resumeData.portfolio.length;
    //let nextProjectIndex = 0;
    
    const sentences =[page];
    
    const splitSentences = sentences.map((sentence) => sentence.split("-"));
    const capitalizeSplitSentences = splitSentences.map(sentence => sentence.map((word) => word.charAt(0).toUpperCase() + word.slice(1)));
    const joinedCapitalizedSplitSentences = capitalizeSplitSentences.map((sentences) => sentences.join(" "));
    
    const projectName = joinedCapitalizedSplitSentences[0];
      
    //const projectName = this.Capitalize(page.replace(/-/g, ' '));
    
    //const caplializePage = splitPage.map(splitPage => splitPage.map((word) => word.charAt(0).toUpperCase() + word.slice(1)));
    //const joinedCapitalizedPage = caplializePage.map((sentences) => splitPage.join(" "));
    
    const project = resumeData.portfolio.find((element) => element.name === projectName);
      
    const projectIndex = resumeData.portfolio.findIndex(x => x.name === projectName);
      
    let nextProjectIndex = projectIndex + 1;
    let prevProjectIndex = projectIndex - 1;
      
    if (nextProjectIndex === resumeData.portfolio.length) {
        nextProjectIndex = 0;   
    }
      
    if (projectIndex === 0) {
        prevProjectIndex = resumeData.portfolio.length - 1;
    }
      
    const nextProject = resumeData.portfolio.find((element, index) => index === nextProjectIndex);
    const prevProject = resumeData.portfolio.find((element, index) => index === prevProjectIndex);
      
    //console.log(prevProjectIndex, projectIndex, nextProjectIndex);
    //console.log(prevProject, project, nextProject);
    
    ReactGA. send ({ 
      hitType: "pageview", 
      page: "/" + section + "/" + page,
      title: projectName + " Case Study",
    });
      
    const routeVariants = {
        initial: {
            y: '50vh'
        },
        final: {
            y: '0vh',
            transition: {
              type: "spring",
              mass: 0.4,
            },
        }
    };
    
    //console.log("/portfolio/" + section + "/" + page, projectName + " Case Study");
      
    return (
    <div className="container">
    <Header resumeData={resumeData} />
    <motion.div
        variants={routeVariants}
        initial="initial"
        animate="final"
    >
      <section id="project">
          <div id="page-title" className="row">
            <h1 className="page-heading">{resumeData.portfolio[projectIndex].name}</h1>
            <small className="tag">{resumeData.portfolio[projectIndex].description}</small>
          </div>
          <div className="row">
            <div className="content">
            {
            project && project.sections.map((section, index)=>{
              return (
              <div className={index === project.sections.length -1 ? "study-section last " : "study-section "} key={section.name}>
                <h2>{section.name}</h2>
                <div className={"top-level " + section.direction}>
                    {
                     section.columns.map((column, index)=>{
                        if (column.type === "overview") {
                             return(
                                <div className={column.type + " " + column.direction + " " + column.layout} key={column.index}>
                                    <div className="company-info">
                                        <img src={column.logo} />
                                        <h3>{column.company}</h3>
                                        <small>{column.start} &ndash; {column.end}</small>
                                    </div>
                                    <div className="company-meta horizontal">
                                        <div className="group half">
                                            <div className="roles">
                                                <div className="accent heading" style={{color: `rgba(${project.accentColor}, 1)`}}>Role:</div>
                                                    <div className="meta-container">
                                                        {
                                                        column.role.map((role, index)=>{
                                                            return(
                                                                <span key={role.label}>{role.label} {index !== column.role.length -1 ? <span className="meta-divider">&bull;</span> : ""}</span>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </div>
                                                <div className="team">
                                                    <div className="accent heading" style={{color: `rgba(${project.accentColor}, 1)`}}>Team:</div>
                                                        <div className="team-members">
                                                            {
                                                            column.team.map((member, index)=>{
                                                                return(
                                                                    <div key={member.name} className="member">
                                                                        <div>{member.name}</div>
                                                                        <div className="meta-container">
                                                                            {
                                                                            member.role.map((item, index)=>{
                                                                                return(
                                                                                    <span key={item.label}><small>{item.label} {index !== member.role.length -1 ? <span className="meta-divider">&bull;</span> : ""}</small></span>
                                                                                )
                                                                            })
                                                                            }
                                                                        </div>
                                                                    </div>  
                                                                )
                                                            })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="text half">
                                                    <div className="accent heading" style={{color: `rgba(${project.accentColor}, 1)`}}>Overview:</div>
                                                    <span>{column.text}</span>
                                                </div>
                                            </div>
                                        </div>
                             )
                        }
                        else if (column.type === "image-text") {
                             return(
                                <div className={column.type + " " + column.direction + " " + column.layout}>
                                    {
                                     column.images.map((image, index)=>{
                                        return(
                                            <div>
                                             <img src={image.url} />
                                             <caption>{image.caption}</caption>
                                            </div>
                                        )
                                    })
                                    }
                                    {
                                     column.text.map((p, index)=>{
                                        return(
                                            <div className={p.direction}>
                                             <p>{p.paragraph}</p>
                                            </div>
                                        )
                                    })
                                    }
                                </div>
                             )
                        }
                        else if (column.type === "metric") {
                             return(
                                <div className={column.type + " " + column.layout}>
                                    <div>
                                        <h4><span className="step" style={{background: `rgba(${project.accentColor}, 1)`}}>{column.step}</span> {column.title}</h4>
                                    </div>
                                    <div className={column.direction}>
                                        <div className="two-thirds">
                                        {
                                         column.text.map((p, index)=>{
                                            return(
                                                <div key={p.index} className="metric-copy">
                                                 <p>{p.paragraph}</p>
                                                </div>
                                            )
                                        })
                                        }
                                        {
                                         column.links.map((a, index)=>{
                                            return(
                                                <div className="metric-link" key={a.label}>
                                                 <a href={a.url}>{a.label}</a>
                                                </div>
                                            )
                                        })
                                        }
                                        </div>
                                        <div className="one-third">
                                        {
                                         column.images.map((image, index)=>{
                                            return(
                                                <div className="metric-image" key={image.index}>
                                                 <img src={image.url} />
                                                 <caption>{image.caption}</caption>
                                                </div>
                                            )
                                        })
                                        }
                                        {
                                         column.metrics.map((metric, index)=>{
                                            return(
                                                <div className="metric-count" key={metric.index}>
                                                 <h2>{metric.count}</h2>
                                                 <small>{metric.label}</small>
                                                </div>
                                            )
                                        })
                                        }
                                        </div>
                                    </div>
                                </div>
                             )
                        }
                        else if (column.type === "metric-bar") {
                             return(
                                <div className={"metric-bar " + column.direction}>
                                    {
                                     column.metrics.map((metric, index)=>{
                                        return(
                                            <div className="metric-count" key={metric.index}>
                                             <h2>{metric.count}</h2>
                                             <small>{metric.label}</small>
                                            </div>
                                        )
                                    })
                                    }
                                 </div>
                             )
                        }
                        else if (column.type === "side-by-side") {
                             return(
                                 <div className={column.type}>
                                    <ReactCompareSlider
                                      itemOne={<ReactCompareSliderImage src={column.image1} alt="Aegis platform in light mode" />}
                                      itemTwo={<ReactCompareSliderImage src={column.image2} alt="Aegis platform in light mode" />}
                                    />
                                    <caption>{column.caption}</caption>
                                </div>
                             )
                        }
                     })
                    }
                </div>
          </div>
            )
          })
        }
            <div className="project-nav">
              <Link to={"/" + prevProject?.description.toLowerCase() + "/" + prevProject?.name.replace(/\s+/g, '-').toLowerCase()}>
                  <span className="project-nav-button"><PiCaretLeftBold /><span>Prev</span></span>
              </Link>
              <Link to={"/portfolio"}>
                  <span className="project-nav-button"><PiGridFourFill className= "project-home" /></span>
              </Link>
              <Link to={"/" + nextProject?.description.toLowerCase() + "/" + nextProject?.name.replace(/\s+/g, '-').toLowerCase()}>
                  <span className="project-nav-button"><span>Next</span><PiCaretRightBold /></span>
              </Link>    
            </div>
        </div>
      </div>
  </section>
</motion.div>
<Footer resumeData={resumeData} />
</div>
        );
  }
}

//

//<Carousel 
//    responsive={responsive}
//    swipeable={true}
//    showDots={false}
//    arrows={true}
//    infinite={true}
//    deviceType={this.props.deviceType}
//    dotListClass="pagination"
//    minimumTouchDrag="50"
//>
//{
//resumeData.portfolio && resumeData.portfolio.map((item)=>{
//  return(
//    <div className="portfolio-item" key={item.name}>
//          {/*<a href="#modal-01">*/}
//          <img src={item.imgurl} className="item-img"/>
//          <div className="overlay">
//            <div className="portfolio-item-meta">
//              <h5>{item.name}</h5>
//              <p>{item.description}</p>
//            </div>
//          </div>
//        {/*</a>*/}
//    </div>
//  )
//})
//}
//</Carousel>

//style={section.direction === "horizontal" || section.direction === "horizontal-reverse" ? {width: `calc((100% / ${section.columns.length}) - 16px)`} : {width: "100%"}}




