import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export default class Availability extends Component {
  render() {
    let resumeData = this.props.resumeData;
    const url = window.location.href;
    const pieces = url.split("/");
    const page = pieces[pieces.length - 1];
    
      
    return (
        <nav>
            <ul>
               <li className={page === "about" ? "current" : ""}>{page !== "about" ? <Link className="nav-link" to="/about">About</Link> : <span className="nav-link">About</span>}</li>
               <li className={page === "resume" ? "current" : ""}>{page !== "resume" ? <Link className="nav-link" to="/resume">Resume</Link> : <span className="nav-link">Resume</span>}</li>
               <li className={page === "portfolio" ? "current" : ""}>{page !== "portfolio" ? <Link className="nav-link" to="/portfolio">Portfolio</Link> : <span className="nav-link">Portfolio</span>}</li>
               <li className={page === "blog" ? "current" : ""}>{page !== "blog" ? <Link className="nav-link" to="/blog">Blog</Link> : <span className="nav-link">Blog</span>}</li>
               <li className={page === "contact" ? "current" : ""}>{page !== "contact" ? <Link className="nav-link" to="/contact">Contact</Link> : <span className="nav-link">Contact</span>}</li>
            </ul>
        </nav>
        );
  }
}

//<ReactTidyCal className="three columns" path="joshsroufe/intro" />
