import React, { Component } from 'react';

//import { PiStrategyFill } from "react-icons/pi";
//import { PiChalkboardTeacherFill } from "react-icons/pi";
//import { PiPencilRulerFill } from "react-icons/pi";
//import { PiMathOperationsFill } from "react-icons/pi";
//import { PiTreeStructureFill } from "react-icons/pi";
//import { PiCodeFill } from "react-icons/pi";
//import { PiBookOpenTextFill } from "react-icons/pi";
//import { PiChatCircleDotsFill } from "react-icons/pi";

export default class Contact extends Component {
    
  render() {     
  
    let resumeData = this.props.resumeData;
      
    //const componentMap = {
    //  PiStrategyFill,
    //  PiChalkboardTeacherFill,
    //  PiPencilRulerFill,
    //  PiMathOperationsFill,
    //  PiTreeStructureFill,
    //  PiCodeFill,
    //  PiBookOpenTextFill,
    //  PiChatCircleDotsFill
    //};
    //  
    //function DynamicComponent({ componentName }) {
    //  const Component = componentMap[componentName];
//
    //  if (!Component) {
    //    return <p>Component not found: {componentName}</p>;
    //  }
//
    //  return <Component />;
    //}
      
    return (
        <section id="services" className="tiles">
            <div className="row">
                <h4>Available Services</h4>
                <ul>
                    {
                    resumeData.services && resumeData.services.map((item)=>{
                        return(
                            <li className="half" key={item.title}>
                                <span className="service-icon"><span className="material-symbols-rounded">{item.img}</span> <h3>{item.name}</h3></span>
                                <span>{item.summary}</span>
                            </li>
                        )
                    })
                    }
                </ul>
            </div>
        </section>
    );
  }
}


//<DynamicComponent componentName={item.img} />
