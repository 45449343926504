import React, { Component } from 'react';

import { FaCoffee } from "react-icons/fa";

export default class CoffeeButton extends Component {
  render() {
    let resumeData = this.props.resumeData; 
    
    return(
        <a target="_blank" href="https://buymeacoffee.com/joshsroufe" className="coffee-button button">
            <button className="accent"><span class="material-symbols-rounded">coffee</span> Buy me a coffee</button>
        </a>
    )  
  }
}