import React, { Component, useState } from 'react';
import { Link } from 'react-router-dom';

import Close from './Close';
import Footer from './Footer';
import Header from './Header';

import ReactGA from 'react-ga4';
import Carousel from "react-multi-carousel";
import { motion, AnimatePresence } from "framer-motion";

import { PiCaretLeftBold } from "react-icons/pi";
import { PiCaretRightBold } from "react-icons/pi";
import { PiGridFourFill } from "react-icons/pi";

export default class PortfolioProject extends Component {

    Capitalize(str){
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

  render() {
    let resumeData = this.props.resumeData;
    
    
    const url = window.location.href;
    const pieces = url.split("/");
    const page = pieces[pieces.length - 1];
    const section = pieces[pieces.length - 2];
      
    //let prevProjectIndex = resumeData.portfolio.length;
    //let nextProjectIndex = 0;
    
    const sentences = [page];
    
    const splitSentences = sentences.map((sentence) => sentence.split("-"));
    const capitalizeSplitSentences = splitSentences.map(sentence => sentence.map((word) => word.charAt(0).toUpperCase() + word.slice(1)));
    const joinedCapitalizedSplitSentences = capitalizeSplitSentences.map((sentences) => sentences.join(" "));
    
    const projectName = joinedCapitalizedSplitSentences[0];
      
    //const projectName = this.Capitalize(page.replace(/-/g, ' '));
    
    //const caplializePage = splitPage.map(splitPage => splitPage.map((word) => word.charAt(0).toUpperCase() + word.slice(1)));
    //const joinedCapitalizedPage = caplializePage.map((sentences) => splitPage.join(" "));
    
    const project = resumeData.portfolio.find((element) => element.name === projectName);
      
    const projectIndex = resumeData.portfolio.findIndex(x => x.name === projectName);
      
    let nextProjectIndex = projectIndex + 1;
    let prevProjectIndex = projectIndex - 1;
      
    if (nextProjectIndex === resumeData.portfolio.length) {
        nextProjectIndex = 0;
    }
      
    if (projectIndex === 0) {
        prevProjectIndex = resumeData.portfolio.length - 1;
    }
      
    const nextProject = resumeData.portfolio.find((element, index) => index === nextProjectIndex);
    const prevProject = resumeData.portfolio.find((element, index) => index === prevProjectIndex);
      
    //console.log(prevProjectIndex, projectIndex, nextProjectIndex);
    //console.log(prevProject, project, nextProject );
    
    ReactGA. send ({ 
      hitType: "pageview", 
      page: "/" + section + "/" + page,
      title: projectName + " Projects",
    });
      
    const routeVariants = {
        initial: {
            y: '50vh'
        },
        final: {
            y: '0vh',
            transition: {
              type: "spring",
              mass: 0.4,
            },
        }
    };
      
    //console.log("/portfolio/" + section + "/" + page, projectName + " Projects");
      
    return (
    <div className="container">
    <Header resumeData={resumeData} />
    <motion.div
        variants={routeVariants}
        initial="initial"
        animate="final"
    >
      <section id="project">
          <div id="page-title" className="row">
            <h1 className="page-heading">{resumeData.portfolio[projectIndex].name}</h1>
            <small className="tag">{resumeData.portfolio[projectIndex].description}</small>
          </div>
          <div className="row">
            <div className="twelve columns project-container">
            {
            project && project.items.map((item)=>{
              
              let projectId = item.title;
              
              projectId = projectId.toLowerCase();
              
              if (projectId.includes(" ")) {
                projectId = projectId.replace(/\s+/g, '-');
              }
              
              return (
              <div id={projectId} className={"project-item " + item.layout} key={item.title}>
                <h2>{item.title}</h2>
                <div className="project-meta">
                    {
                     item.meta.map((meta, index)=>{
                         return(
                        <span key={meta.label}><small>{meta.label}{index !== item.meta.length -1 ? <span className="meta-divider">&bull;</span> : ""}</small></span>
                        )
                     })
                    }
                </div>
                <div className="project-summary">
                    {
                     item.summary.map((p, index)=>{
                        return(
                            <div>
                             <p>{p.paragraph}</p>
                            </div>
                        )
                    })
                    }
                </div>
                <div className="action-bar">
                    {
                     item.links.map((link)=>{
                         return(
                        <span key={link.label}><a href={link.url} target="_blank">{link.label}</a></span>
                        )
                     })
                    }
                </div>
                <div className={item.layout === "cover" ? "art gradient-overlay" : "art"}><img src={item.imgurl} /></div>
          </div>
            )
          })
        }
            <div className="project-nav">
              <Link to={"/" + prevProject?.description.replace(/\s+/g, '-').toLowerCase() + "/" + prevProject?.name.replace(/\s+/g, '-').toLowerCase()}>
                  <span className="project-nav-button"><PiCaretLeftBold /><span>Prev</span></span>
              </Link>
              <Link to={"/portfolio"}>
                  <span className="project-nav-button"><PiGridFourFill className= "project-home" /></span>
              </Link>
              <Link to={"/" + nextProject?.description.replace(/\s+/g, '-').toLowerCase() + "/" + nextProject?.name.replace(/\s+/g, '-').toLowerCase()}>
                  <span className="project-nav-button"><span>Next</span><PiCaretRightBold /></span>
              </Link>    
            </div>
        </div>
      </div>
  </section>
</motion.div>
<Footer resumeData={resumeData} />
</div>
        );
  }
}

//

//<Carousel 
//    responsive={responsive}
//    swipeable={true}
//    showDots={false}
//    arrows={true}
//    infinite={true}
//    deviceType={this.props.deviceType}
//    dotListClass="pagination"
//    minimumTouchDrag="50"
//>
//{
//resumeData.portfolio && resumeData.portfolio.map((item)=>{
//  return(
//    <div className="portfolio-item" key={item.name}>
//          {/*<a href="#modal-01">*/}
//          <img src={item.imgurl} className="item-img"/>
//          <div className="overlay">
//            <div className="portfolio-item-meta">
//              <h5>{item.name}</h5>
//              <p>{item.description}</p>
//            </div>
//          </div>
//        {/*</a>*/}
//    </div>
//  )
//})
//}
//</Carousel>

