import React, { Component, useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
//import { Link, animateScroll as scroll } from 'react-scroll';
import { RWebShare } from "react-web-share";

import Close from './Close';
import Share from './Share';
import ScrollToTop from './ScrollToTop';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import { motion, AnimatePresence } from "framer-motion";
import { Helmet } from 'react-helmet';
import Availability from './Availability';
import Nav from './Nav';

import { FaPaypal } from "react-icons/fa6";
import { FaDownload } from "react-icons/fa6";
import { FaMedium } from "react-icons/fa6";
import { FaShare } from "react-icons/fa";
import { FaAddressCard } from "react-icons/fa6";
import { FaXmark } from "react-icons/fa6";
import { FaBars } from "react-icons/fa6";


const Header = (props) => {
    
    const [scrolling, setScrolling] = useState(false);
    
    let sharePage = false;
    
    const url = window.location.href;
    const pieces = url.split("/");
    let page = pieces[pieces.length - 1];
    const section = pieces[pieces.length - 2];
    
    let blogUrl = "";
    let blogTitle = "";
    let blogPage = "";
  
    function cleanString(input) {
        var output = "";
        for (var i=0; i<input.length; i++) {
            if (input.charCodeAt(i) <= 127) {
                output += input.charAt(i);
            }
        }
        return output;
    }
  
  if (page.includes("?")) {
    blogUrl = page.split("?");
    page = blogUrl[0];
  }
      
    let action_url = "/";
    let action_title = "Menu";
    let action_icon = <FaBars />;
     
    //if (page === "about") {
    //    action_url = "https://paypal.me/joshsroufe";
    //    action_title = "Send a Donation";
    //    action_icon = <FaPaypal />
    //}
    //else if (page === "resume" || page === "about") {
    //    action_url = "/files/josh_sroufe_resume.pdf";
    //    action_title = "Download Resume";
    //    action_icon = <FaDownload />
    //}
    //else if (page === "portfolio") {
    //    action_url = "/files/josh_sroufe_portfolio.pdf";
    //    action_title = "Download Portfolio";
    //    action_icon = <FaDownload />
    //}
    //else if (page === "contact") {
    //    action_url = "/files/joshsroufe.vcf";
    //    action_title = "Add to Contacts";
    //    action_icon = <FaAddressCard />
    //}
    //else if (page === "blog") {
    //    action_url = "https://medium.com/@joshsroufe";
    //    action_title = "View on Medium";
    //    action_icon = <FaMedium />
    //}
    //else if (page === "blog-post" || page === "pulse-labs" || page === "house-canary" || page === "clear-capital" || page === "apple" || page === "miscellaneous"){
    //    action_url = "/";
    //    //action_title = "Share";
    //    action_icon = <FaShare />
    //    sharePage = true; 
    //}
    //else if (page === "aegis-smart-home"){
    //    action_url = "/files/josh_sroufe_aegis_case_study.pdf";
    //    //action_title = "Share";
    //    action_title = "Download Case Study";
    //    action_icon = <FaDownload />
    //    //sharePage = true; 
    //}
    
    tippy('.header-action', {
        content: action_title,
        duration: 500,
        arrow: true,
        delay: [1000, 200],
        touch: ['hold', 500],
        arrow: false,
      });
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
      }, []);
    
    const handleScroll = () => {
        if (window.scrollY >= 1) {
          setScrolling(true);
        } else {
          setScrolling(false);
        }
      };
    
    const childVariants = {
      initial: {
        opacity: 0,
        y: "-200px",
      },
      final: {
        opacity: 1,
        y: "0px",
        transition: {
          duration: 0.3,
          delay: 0,
          type: "Spring",
          mass: 0.4,
        },
      },
    };
    
    //console.log(scrolling);
  
    return (
    
    <motion.header 
      className={scrolling ? 'navbar-scroll' : ''}
      variants={childVariants} 
      initial="initial" 
      animate="final"
    >
      <Helmet>
        <meta property="og:title" content="Josh Sroufe, Design Director from CA" />
        <meta property="og:description" content="Josh Sroufe is an entrepreneur, design director, husband & father, and Mixed Martial Artist from CA." />
        <meta property="og:url" content="https://joshsroufe.com/" />
        <meta property="og:image" content="https://joshsroufe.com/images/brand/graph_default.png" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="article:author" content="Josh Sroufe" />
        <meta property="og:site_name" content="Josh Sroufe" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@joshuasroufe" />
      </Helmet>
      <ScrollToTop />
      <div className="row">
        <div className="header-content">
          <Link to="/" ><img className="profile-pic" id="logo" src={props.resumeData.logos[0].url} alt="Josh Sroufe" /></Link>
          <Availability resumeData={props.resumeData} />
        </div>
        <div className="top-actions">
            <Nav className="header-nav" />
            <Link to="/" className="header-action">{action_icon}</Link>
        </div>
      </div>
    </motion.header>
    );

};

export default Header;

//<Close />
//title={action_title}

//{action_url && sharePage ? <Share /> : <a href={action_url} target="_blank" className="header-action">{action_icon}</a>}

//<div className="details">
//  <h2>{props.resumeData.name}</h2>
//  <span className="page-title">{section === "projects" || section === "case-study" || page === "blog-post" ? page.replace(/-/g, ' ') : page} {section === "projects" ? section.replace(/-/g, ' ') : ""} <Share /></span>
//</div>
//

          //<div className="details">
          //  <h2>{props.resumeData.name}</h2>
          //</div>
