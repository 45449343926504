import React, { useState } from 'react';
//import './Modal.css'; // Import custom styles

import { IoClose } from "react-icons/io5";

const Modal = ({ isOpen, onClose, children }) => {
  if (!isOpen) {
    return null;
  }

  const handleOverlayClick = (event) => {
    if (event.target === event.currentTarget) {
      onClose();
    }
  };
  
  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div id="modal" className="modal-container animate__animated animate__slideInUp animate__faster">
          <button className="modal-close" onClick={onClose}>
            <IoClose />
          </button>
          <div className="modal-content">
            {children}
          </div>
        </div>
    </div>
  );
};

export default Modal;
