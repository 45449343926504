import React, {useState, useEffect} from 'react';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';
import moment from 'moment';

import 'animate.css';
import { FaExternalLinkSquareAlt } from "react-icons/fa";

export default () => {
  const { width, height } = useWindowSize();
    
    moment().format("MM/DD/YYYY");
    const now = moment(new Date());
    let today = moment().format("MMM Do");
    let born = moment('1984-11-12');
    let birthday = today == "Nov 12th";
    //birthday = today == today;
    let age = now.diff(born, 'years', true);
    
    let message =  <dialog id="notification" className="animate__animated animate__slideInDown" open><a target="_blank" href="https://buymeacoffee.com/joshsroufe"><div className="notify"><div className="emoji">🎉</div><div className="cta">Thanks for visiting on my birthday, help me celebrate by buying me a coffee.<span class="material-symbols-rounded">open_in_new</span></div></div></a></dialog>;
    
    //message = setTimeout(message, 5000)

    const [show, setShow] = useState(true)

      // On componentDidMount set the timer
      useEffect(() => {
          
        const element = document.querySelector('#notification');

        if (element) {
            element.addEventListener('animationend', () => {
                setTimeout(function() {
                    element.classList.add('animate__animated', 'animate__slideOutUp');
                }, 6000)
            });
        }
          
        const timeId = setTimeout(() => {
          // After 6 seconds set the show value to false
          setShow(false)
        }, 20208000)

        return () => {
          clearTimeout(timeId)
        }
      }, []);

      // If show is false the component will return null and stop here
      if (!show) {
        return null;
      }

    
  if (birthday) {
      return (
        <div>
            <Confetti
              width={width}
              height={height}
              recycle={false}
              numberOfPieces={250}
            />
            {show && message}
        </div>
      );
  }
  else {
      return null;
  }
};
