import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import axios from "axios";


import Footer from './Footer';
import Header from './Header';

import ReactGA from 'react-ga4';
import moment from 'moment';
import { motion, AnimatePresence } from "framer-motion";

import "highlight.js/styles/github.css";
import hljs from "highlight.js";

import SyntaxHighlighter from 'react-syntax-highlighter';
import { foundation } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { FaChevronLeft } from "react-icons/fa6";

import { PiCaretLeftBold } from "react-icons/pi";
import { PiCaretRightBold } from "react-icons/pi";
import { PiGridFourFill } from "react-icons/pi";
import { PiRowsFill } from "react-icons/pi";

const BlogPost = (props) => {
    const [posts, setPosts] = useState([]);
    
    const location = useLocation();
    const navigate = useNavigate();
  
    let data = location.state;
    
    let postContent = [];
    
    let allData = [];
    
    const url = window.location.href;
    const pieces = url.split("/");
    const page = pieces[pieces.length - 1];
    const section = pieces[pieces.length - 2];
    
    const blogUrl = page.split("?");
    const blogTitle = blogUrl[1];
    const blogPage = blogTitle.replace(/-/g, " ");
    //const blogPage = "Your worst nightmare realized";
    
    useEffect(() => {
        if (!data) {

            const getPostData = () => {
            axios
              .get("https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@joshsroufe")
              .then((res) => {
                setPosts(res.data.items);
              })
              .catch((error) => {
                console.error("Error fetching blog posts:", error);
              });
            };

            getPostData();
            //else {
            //    navigate("/blog");
            //}
        }
    }, []);

    
    let resumeData = props.resumeData;
    
    const [scrolling, setScrolling] = useState(false);
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);
    
    const handleScroll = () => {
        if (window.scrollY >= 96) {
          setScrolling(true);
        } else {
          setScrolling(false);
        }
    };
    
    
    function removeTags(str) {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();

        // Regular expression to identify HTML tags in
        // the input string. Replacing the identified
        // HTML tag with a null string.
        return str.replace(/(<([^>]+)>)/ig, '');
    }
    
    useEffect(() => {
        ReactGA. send ({ 
          hitType: "pageview", 
          page: "/" + page,
          title: "Blog Post",
        });
    }, [page]);
    
    const routeVariants = {
        initial: {
            y: '50vh',
        },
        final: {
            y: '0vh',
            transition: {
              type: "spring",
              mass: 0.4,
            },
        }
    };
 
    const regex = /<h3>(.*?)<\/h3>/;
    
    
    if(posts.length !== 0) {
        allData = posts;
        data = posts.find((post) => post.title.toLowerCase() === blogPage);
    }
    else if (data) {
        allData = data;
        data = data.find((post) => post.title.toLowerCase() === blogPage);
    }
    else {
        console.log(data, posts);
    }
    
    if (allData && allData.length < 1) {
        navigate("/blog");
    }
      
    const dataIndex = allData.findIndex(x => x.title.toLowerCase() === blogPage);
      
    let nextDataIndex = dataIndex + 1;
    let prevDataIndex = dataIndex - 1;
      
    if (nextDataIndex === allData.length) {
        nextDataIndex = 0;
    }
      
    if (dataIndex === 0) {
        prevDataIndex = allData.length - 1;
    }
      
    const nextArticle = allData.find((element, index) => index === nextDataIndex);
    const prevArticle = allData.find((element, index) => index === prevDataIndex);
    
    
    document.querySelectorAll("pre").forEach((el) => {
      hljs.highlightElement(el);
    });
    
    
    //console.log(data);
    
    if (data) {
        postContent = data.content.replace(regex, ''); 
    }
    
  
  return (
       data && 
      <div className="container">
      <Header resumeData={resumeData} />
      <motion.div
            variants={routeVariants}
            initial="initial"
            animate="final"
        >
            <section id="blog-post">
                <div className="row">
                    <div id="blog">
                        <div id="page-title" className="title">
                            <h3>{data.title}</h3>
                            <small className="tag">{moment(data.pubDate).format("MMM Do, YYYY")}</small>
                        </div>
                        <div className="post-content" dangerouslySetInnerHTML={{ __html: postContent.replace(/<br>/g, '\n') }} />
                    </div>
                    <div className="project-nav">
                      <Link to={"/blog-post?" + prevArticle?.title.replace(/\s+/g, '-').toLowerCase()} state={allData}>
                          <span className="project-nav-button"><PiCaretLeftBold /><span>Prev</span></span>
                      </Link>
                      <Link to={"/blog"}>
                          <span className="project-nav-button"><PiRowsFill className= "project-home" /></span>
                      </Link>
                      <Link to={"/blog-post?" + nextArticle?.title.replace(/\s+/g, '-').toLowerCase()} state={allData}>
                          <span className="project-nav-button"><span>Next</span><PiCaretRightBold /></span>
                      </Link>    
                    </div>
                </div>
            </section>
      </motion.div>
      <Footer resumeData={resumeData} />
      </div>
  );
};
export default BlogPost;


//<Link to="/blog" className={scrolling ? 'back-button scrolled animate__animated animate__slideInDown' : 'back-button'}>
//        <FaChevronLeft /> 
//        <span>Back</span>
//</Link>