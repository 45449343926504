import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as ReactDOM from 'react-dom';

import Footer from './Footer';
import Header from './Header';
import ContactForm from './ContactForm';
import Services from './Services';

//import { ReactTidyCal } from "react-tidycal";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ReactGA from 'react-ga4';
import { motion, AnimatePresence } from 'framer-motion';

import Modal from './Modal';
//import AppleMusic from './AppleMusic';
import Faq from 'react-faq-component';

import moment from 'moment-timezone';

import { PiMapPinLineFill } from "react-icons/pi";

//export default class Contact extends Component {
const Contact = (props) => { 
  //render() {     
      
    ReactGA. send ({ 
      hitType: "pageview", 
      page: "/contact",
      title: "Contact",
    });
      
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
    };
      
    const routeVariants = {
        initial: {
            y: '50vh'
        },
        final: {
            y: '0vh',
            transition: {
              type: "spring",
              mass: 0.4,
            },
        }
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        document.getElementById("modal").className="modal-container animate__animated animate__slideOutDown animate__faster";
        setTimeout(() => setIsModalOpen(false), 400);
    };
      
    
    let resumeData = props.resumeData;
      
    return (
        <div className="container">
        <Header resumeData={resumeData} />
        <motion.div
            variants={routeVariants}
            initial="initial"
            animate="final"
        >
            <section id="contact">
                <div id="page-title" className="row">
                    <h1 className="page-heading">Contact</h1>
                </div>
                <div className="row">
                    <div className="content">
                        <div id="meetings" className="half">
                            <div className="meeting-container">
                                <div className="meetings-label">
                                    <h4>Schedule a Meeting</h4>
                                </div>
                                {
                                    resumeData.availability === "unavailable" && 
                                        <div>
                                            <div className="meeting-header">
                                                <h3>{resumeData.meetings[0].title}</h3>
                                                <span className="small">({resumeData.meetings[0].duration} min)</span> 
                                            </div>
                                            <p>{resumeData.meetings[0].description}</p>
                                            <a href={resumeData.meetings[0].url} target="_blank"><button className="accent">Book now {resumeData.meetings[0].price === 0 ? '' : "$"+resumeData.meetings[0].price}</button></a>
                                        </div>
                                }
                                {
                                    resumeData.availability === "available" && 
                                        <Carousel 
                                            responsive={responsive}
                                            swipeable={true}
                                            showDots={true}
                                            infinite={true}
                                            arrows={false}
                                            deviceType={props.deviceType}
                                            autoPlay={props.deviceType !== "mobile" ? true : false}
                                            autoPlaySpeed={10000}
                                            dotListClass="pagination"
                                            minimumTouchDrag="50"
                                        >
                                        {
                                            resumeData.meetings && resumeData.meetings.map((item)=>{
                                                return(
                                                    <div key={item.title}>
                                                        <div className="meeting-header">
                                                            <h3>{item.title}</h3>
                                                            <span className="small">({item.duration >= 60 ? item.duration / 60 : item.duration} {item.duration >= 60 ? "hr" : "min"})</span>    
                                                        </div>
                                                        <p>{item.description}</p>
                                                        <div className="button-group">
                                                            {item.price > 0 ? <button onClick={openModal}><span className="material-symbols-rounded">cards_star</span> Explore Services</button> : "" }
                                                            <a href={item.url} target="_blank"><button className="accent"><span className="material-symbols-rounded">event_available</span> Book now {item.price === 0 ? '' : "$"+item.price}</button></a>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                        </Carousel>
                                }
                            </div>
                        </div>
                        <div id="messages" className="half">
                            <div className="message-container">
                                <div className="message-label">
                                    <h4>Send a Message</h4>
                                </div>
                                <ContactForm />
                            </div>
                        </div>
                        <Faq data={resumeData.faq}/>
                    </div>
                </div> 
            </section>
        </motion.div>
        <Footer resumeData={resumeData} />
        <Modal isOpen={isModalOpen} onClose={closeModal}>
            <Services resumeData={resumeData} />
        </Modal>
        </div>
    );
  //}
};

export default Contact;
//<Map resumeData={resumeData} />

//<Carousel 
//    responsive={responsive}
//    swipeable={true}
//    showDots={true}
//    infinite={true}
//    autoPlay={this.props.deviceType !== "mobile" ? true : false}
//    autoPlaySpeed={10000}
//    removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
//    deviceType={this.props.deviceType}
//    dotListClass="pagination"
//    minimumTouchDrag="50"
//>
//{
//resumeData.meetings && resumeData.meetings.map((item)=>{
//  return(
//
//        <div key={item.title}>
//      { /*<img src={item.img} className="photo" /> */}
//            <div className="meeting-header">
//                <h3>{item.title}</h3>
//                <span className="duration">({item.duration} min)</span>    
//            </div>
//            <p>{item.description}</p>
//            <a href={item.url} target="_blank"><button className="accent">Book now {item.price === 0 ? '' : "$"+item.price}</button></a> 
//          </div>
//        )
//    })
//  }
//</Carousel>

//<div className="twelve columns">
//    <h2>Ready to connect?</h2>
//    <p>Use the form to send a message or you can request a meeting.</p>
//</div>

//<ReactTidyCal path="joshsroufe/intro" />
//

//<div className="map"></div>

            //<Modal isOpen={isModalOpen} onClose={closeModal}>
            //    <h2>Modal Title</h2>
            //    <Services />
            //    <button onClick={closeModal}>Close</button>
            //</Modal>

