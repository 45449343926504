import { useEffect, useState } from "react";
import { getUserLocation } from "../utils/location";
import { fetchWeatherData } from "../utils/weather";
import moment from "moment";

const Weather = (props) => {
  const [weather, setWeather] = useState(null);
  const [weatherIssued, setWeatherIssued] = useState(
    localStorage.getItem("weather_issued"),
  );

  const [coords, setCoords] = useState(null);
  const [error, setError] = useState(null);
  const [latitude, setLatitude] = useState(
    localStorage.getItem("latitude")
      ? localStorage.getItem("latitude")
      : props.lat,
  );
  const [longitude, setLongitude] = useState(
    localStorage.getItem("longitude")
      ? localStorage.getItem("longitude")
      : props.long,
  );

  const [userLocation, setUserLocation] = useState(
    localStorage.getItem("latitude") || localStorage.getItem("longitude")
      ? true
      : false,
  );

  let moment = require("moment-timezone");

  const [defaultTimezone, setDefaultTimezone] = useState(
    moment.tz.setDefault("America/Los_Angeles"),
  );
  const [time, setTime] = useState(new Date());
  const [hours, setHours] = useState(hoursSince(weatherIssued));
  const [timezone, setTimezone] = useState("America/Los_Angeles");
  const [localOffset, setLocalOffset] = useState();
  const [timeOfDay, setTimeOfDay] = useState("default");
  const now = moment(time).tz(timezone);

  const [sunrise, setSunrise] = useState(null);
  const [sunset, setSunset] = useState(null);
  const [clouds, setClouds] = useState(null);
  const [rain, setRain] = useState(0);
  const [snow, setSnow] = useState(0);

  function hoursSince(time) {
    const now = moment();
    const past = moment(time);
    return now.diff(past, "minutes");
  }

  const handleGetWeather = async () => {
    const result = await fetchWeatherData(latitude, longitude);
    if (result) {
      localStorage.setItem("weather", JSON.stringify(result.data));
      setWeather(result.data);
      setWeatherIssued(result.weatherIssued);
      setLocalOffset(result.localOffset);
      setTimezone(result.timezone);

      setSunrise(result.data.sys.sunrise);
      setSunset(result.data.sys.sunset);
      setClouds(result.data.clouds.all);

      if (result.data.rain) {
        setRain(result.data.rain["1h"]);
      }
      if (result.data.snow) {
        setSnow(result.data.snow["1h"]);
      }

      weatherBgUpdate(sunrise, sunset, clouds);
    }
  };

  const handleGetLocation = async () => {
    try {
      const location = await getUserLocation();
      setCoords(location);
      setLatitude(location.latitude);
      setLongitude(location.longitude);

      localStorage.setItem("latitude", location.latitude);
      localStorage.setItem("longitude", location.longitude);

      setError(null);
      setUserLocation(true);

      await handleGetWeather();
    } catch (err) {
      console.error(err);
      setError("Could not get location.");
    }
    // console.log(latitude, longitude);
  };

  function localTime(offset) {
    const getZoneFromOffset = (offsetString) => {
      return moment.tz
        .names()
        .filter((tz) => moment.tz(tz).format("Z") === offsetString);
    };

    let tzFormat = offset / 36;
    tzFormat = tzFormat.toString();

    if (tzFormat.slice(0, 1) === "-") {
      if (tzFormat.length === 4) {
        tzFormat =
          tzFormat.slice(0, 1) +
          0 +
          tzFormat.slice(1, 2) +
          ":" +
          tzFormat.slice(2);
      } else {
        tzFormat =
          tzFormat.slice(0, 1) + tzFormat.slice(1, 3) + ":" + tzFormat.slice(3);
      }
    } else {
      if (tzFormat.length === 4) {
        tzFormat =
          "+" +
          tzFormat.slice(0, 1) +
          tzFormat.slice(1, 2) +
          ":" +
          tzFormat.slice(2);
      } else {
        tzFormat = "+0" + tzFormat.slice(0, 1) + ":" + tzFormat.slice(2) + "0";
      }
    }

    if (offset === 0 || offset === "0") {
      setTimezone("Etc/Universal");
    } else {
      setTimezone(getZoneFromOffset(tzFormat)[0]);
    }

    //console.log(timezone);
  }

  function weatherBgUpdate(sunrise, sunset, clouds) {
    //const sunrise = moment.unix(sunrise).tz(timezone);
    //const sunset = moment.unix(sunset).tz(timezone);
    //const clouds = clouds;

    sunrise = moment.unix(sunrise).tz(timezone);
    sunset = moment.unix(sunset).tz(timezone);

    if (
      Math.abs(sunrise.diff(now, "minutes")) >= 1440 ||
      Math.abs(sunset.diff(now, "minutes")) >= 1440
    ) {
      setTimeOfDay("default");
    } else if (
      (sunset.diff(now, "minutes") <= -21 ||
        sunrise.diff(now, "minutes") >= 21) &&
      clouds >= 40
    ) {
      setTimeOfDay("night_cloudy");
    } else if (
      sunset.diff(now, "minutes") <= -21 ||
      sunrise.diff(now, "minutes") >= 21
    ) {
      setTimeOfDay("night");
    } else if (
      sunrise.diff(now, "minutes") <= -21 &&
      sunset.diff(now, "minutes") >= 21 &&
      clouds >= 40
    ) {
      setTimeOfDay("day_cloudy");
    } else if (
      sunrise.diff(now, "minutes") <= -21 &&
      sunset.diff(now, "minutes") >= 21
    ) {
      setTimeOfDay("day");
    } else if (
      Math.abs(sunrise.diff(now, "minutes")) <= 20 ||
      Math.abs(sunset.diff(now, "minutes")) <= 20
    ) {
      setTimeOfDay("twilight");
    } else {
      setTimeOfDay("default");
    }

    //console.log("Weather BG Update", "Sunrise: " + sunrise.format('M/D/y h:mm a'), "Sunset: " + sunset.format('M/D/y h:mm a'), "Clouds: " + clouds);
    //console.log("Update weather background");
    //console.log(clouds, sunrise, sunrise.format('M/D/y h:mm a'), sunrise.diff(now, 'minutes'), sunset, sunset.format('M/D/y h:mm a'), sunset.diff(now, 'minutes'));
    //console.log(now.format('M/D/y h:mm a'), sunrise.diff(now, 'minutes'), sunrise.format('M/D/y h:mm a'), sunset.diff(now, 'minutes'), sunset.format('M/D/y h:mm a'), sunset.diff(sunrise, 'minutes'));
  }

  useEffect(() => {
    if (!latitude || !longitude) return;

    if (localStorage.getItem("weather") && hours < 10) {
      setWeather(JSON.parse(localStorage.getItem("weather")));
      setLocalOffset(
        localTime(JSON.parse(localStorage.getItem("weather")).timezone),
      );
      setSunrise(JSON.parse(localStorage.getItem("weather")).sys.sunrise);
      setSunset(JSON.parse(localStorage.getItem("weather")).sys.sunset);
      setClouds(JSON.parse(localStorage.getItem("weather")).clouds.all);

      if (JSON.parse(localStorage.getItem("weather")).rain) {
        setRain(JSON.parse(localStorage.getItem("weather")).rain["1h"]);
      }
      if (JSON.parse(localStorage.getItem("weather")).snow) {
        setSnow(JSON.parse(localStorage.getItem("weather")).snow["1h"]);
      }

      weatherBgUpdate(
        JSON.parse(localStorage.getItem("weather")).sys.sunrise,
        JSON.parse(localStorage.getItem("weather")).sys.sunset,
        JSON.parse(localStorage.getItem("weather")).clouds.all,
      );
    } else {
      if (
        localStorage.getItem("latitude") &&
        localStorage.getItem("longitude")
      ) {
        handleGetLocation();
      } else {
        handleGetWeather();
      }
    }

    const interval = setInterval(() => {
      if (
        localStorage.getItem("latitude") &&
        localStorage.getItem("longitude")
      ) {
        handleGetLocation();
      } else {
        handleGetWeather();
      }
    }, 600000);

    return () => clearInterval(interval);
  }, [latitude, longitude]);

  useEffect(() => {
    const interval = setInterval(() => {
      setDefaultTimezone(moment.tz.setDefault(timezone));
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, [weather]);

  useEffect(() => {
    const interval = setInterval(() => {
      weatherBgUpdate(sunrise, sunset, clouds);
    }, 1000);

    return () => clearInterval(interval);
  }, [weather, sunrise, sunset, clouds]);

  // console.log(userLocation);

  if (!weather) return <p>Loading weather...</p>;

  return (
    <div className={"map-content " + timeOfDay}>
      <div
        className={
          snow > 0
            ? "map-metrics snow"
            : rain > 0
              ? "map-metrics rain"
              : clouds >= 40
                ? "map-metrics clouds"
                : clouds > 0
                  ? "map-metrics cloud"
                  : timeOfDay === "night"
                    ? "map-metrics stars"
                    : timeOfDay === "day"
                      ? "map-metrics sun"
                      : timeOfDay === "twilight"
                        ? "map-metrics sprinkle"
                        : "map-metrics"
        }
      >
        <div className="location">
          <span className="location-name">{weather.name}</span>
          <span className="mobile-hide">, </span>{" "}
          <span className="country">{weather.sys.country} </span>
          <span
            id={userLocation ? "location-set" : "location-unset"}
            className={
              hours < 10 && userLocation
                ? "material-symbols-rounded location-icon disabled"
                : "material-symbols-outlined location-icon"
            }
            onClick={
              hours > 10 || !userLocation ? handleGetLocation : undefined
            }
          >
            near_me
          </span>
        </div>
        <div className="date-time">
          <div className="time">
            {now.format("h")}
            <span className="seperator">:</span>
            {now.format("mm A")}
          </div>
          <div className="date">{now.format("ddd, MMM Do")}</div>
        </div>
        <div className="weather">
          <span className="weather-main">
            <img src={"/images/weather/" + weather.weather[0].icon + ".svg"} />{" "}
            <span className="weather-temp">
              {Math.round(weather.main.temp)}&deg;
            </span>
          </span>{" "}
          <span className="weather-details">
            {weather.weather[0].description}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Weather;

//<h4>Currently in {weather.name}</h4>
