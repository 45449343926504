import React from 'react';
import * as ReactDOM from 'react-dom';
import { useNavigate, useLocation } from 'react-router-dom';

import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

const ThemeSwitch = ({onChange}) => {
  
  const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
  
  const savedTheme = localStorage.getItem('theme');
  
  const [theme, setTheme] = React.useState(savedTheme);
  
  const [dark, setDark] = React.useState(prefersDark.matches);
  
  const location = useLocation();
  const navigate = useNavigate();
    
  //prefersDark.addEventListener("change", initDarkMode);

  //prefersDark.addEventListener('change', e => function(){
  //    setDark(e.matchess);
  //    initDarkMode();
  //  });
    
  //prefersDark.addListener(e => e.matches && setDark(e.matches) && initDarkMode() && e.matches ? document.getElementById('theme').className = "App dark" : "App");
    
  function initTheme() {
      
    const div = document.getElementById('theme');

    const divNotify = document.getElementsByClassName('onesignal-bell-container');

    //if (document.getElementsByClassName('onesignal-bell-container')) {
      //divNotify = document.getElementsByClassName('onesignal-bell-container onesignal-reset onesignal-bell-container-bottom-left');
    //}


    //console.log(savedTheme, theme)
    
    //if(div !== null && div){
      
      
        if (savedTheme === "1") {
            setTheme(1);
            div.className = "App";

            //if (divNotify){
              divNotify.className ="onesignal-bell-container onesignal-reset onesignal-bell-container-bottom-left";
            //}
        }
        else if (savedTheme === "2"){
            setTheme(2);
            div.className = "App dark";
            //if (divNotify){
              divNotify.className ="onesignal-bell-container onesignal-reset onesignal-bell-container-bottom-left dark";
            //}
        }
        else if (savedTheme === null || savedTheme === "0") {
            setTheme(0);
            if (dark) {
               div.className = "App dark";
               //if (divNotify){
                 divNotify.className ="onesignal-bell-container onesignal-reset onesignal-bell-container-bottom-left dark";
               //}
            }
            else {
               div.className = "App";
               //if (divNotify){
                 divNotify.className ="onesignal-bell-container onesignal-reset onesignal-bell-container-bottom-left";
               //}
            }
        }
    //}
  }
    
  //function clearTheme() {
  //  localStorage.removeItem('dark-theme');
  //  initDarkMode();
  //}
    
  //const toggleDarkMode = (checked: boolean) => {
  //  setDarkMode(checked);
  //  
  //  const theme = document.getElementById('theme');
  //    
  //  if(theme !== null && theme){
  //      theme.classList.toggle("dark");
  //      localStorage.setItem('dark-theme', isDarkMode ? false : true);
  //  }
  //  
  //};
  
  const handleClick = () => {
    let newTheme = (theme + 1) % 3;

    //if ((theme === 0 || theme === null) && prefersDark.matches) {
    //  newTheme = 1;
    //}
    //else if (theme === 1 && prefersDark.matches) {
    //  newTheme = 2;
    //}
    //else if (theme === 2 && !prefersDark.matches) {
    //  newTheme = 1;
    //}
    //else if ((theme === 0 || theme === null) && !prefersDark.matches) {
    //  newTheme = 2;
    //}
    
    
    setTheme(newTheme);
    
    onChange && onChange(newTheme);
    
    const div = document.getElementById('theme');
    
    localStorage.setItem('theme', newTheme);
    
    if (newTheme === 2) {
      div.className = "App dark";
    }
    else {
      div.className = "App";
    }
    
    //console.log(newTheme);
  };
  
  const handleClickSystem = () => {
    //const newTheme = (theme + 1) % 3;
    
    const newTheme = 0;
    
    setTheme(newTheme);
    
    onChange && onChange(newTheme);
    
    const div = document.getElementById('theme');
    
    localStorage.setItem('theme', newTheme);
    
    if (newTheme === 2) {
      div.className = "App dark";
    }
    else {
      div.className = "App";
    }
    
    //console.log(newTheme);
  };
  
  const handleClickLight = () => {
    //const newTheme = (theme + 1) % 3;
    
    const newTheme = 1;
    
    setTheme(newTheme);
    
    onChange && onChange(newTheme);
    
    const div = document.getElementById('theme');
    
    localStorage.setItem('theme', newTheme);
    
    if (newTheme === 2) {
      div.className = "App dark";
    }
    else {
      div.className = "App";
    }
    
    //console.log(newTheme);
  };
  
  const handleClickDark = () => {
    //const newTheme = (theme + 1) % 3;
    
    const newTheme = 2;
    
    setTheme(newTheme);
    
    onChange && onChange(newTheme);
    
    const div = document.getElementById('theme');
    
    localStorage.setItem('theme', newTheme);
    
    if (newTheme === 2) {
      div.className = "App dark";
    }
    else {
      div.className = "App";
    }
    
    //console.log(newTheme);
  };
    
    //React.useEffect(() => {      
    //    initDarkMode();
    //  }, []);
    
    React.useLayoutEffect(() => {
      
        initTheme();
        
        prefersDark.addEventListener('change', (event) => {
            setDark(event.matches);
            
            const div = document.getElementById('theme');
          
            if (theme === 2 || (event.matches && (savedTheme === null || savedTheme === "0"))) {
              div.className = "App dark";
            }
            else {
              div.className = "App";
            }
   
            //initDarkMode();
            //console.log(prefersDark.matches);

        });
    }, [dark, theme, savedTheme]);

    //    /* You end up here only when the user takes action 
    //    to change the theme, hence you can just apply the new theme. */
    //    initDarkMode();
    //}, [dark]);
    
    
    //if (localStorage.getItem('dark-theme') !== null) {
    //    toolTipContent = '<span style="cursor: pointer;" onClick=`${localStorage.removeItem("dark-theme")};${location.reload()}`><span className="material-symbols-rounded" style="font-size: 1em; position: relative; top: 2px;">routine</span> Revert to system setting</span>';
    //}
    
    //tippy('.theme-toggle.reset', {
    //    content: toolTipContent,
    //    duration: 500,
    //    arrow: true,
    //    delay: [1000, 200],
    //    interactive: true,
    //    interactiveBorder: 30,
    //    interactiveDebounce: 75,
    //    allowHTML: true,
    //    touch: ['hold', 500],
    //    arrow: false,
    //  });
    
    //tippy('.theme-toggle', {
    //    content: "Change Theme",
    //    duration: 500,
    //    arrow: true,
    //    delay: [1000, 200],
    //    interactive: true,
    //    interactiveBorder: 30,
    //    interactiveDebounce: 75,
    //    allowHTML: true,
    //    touch: ['hold', 500],
    //    arrow: false,
    //  });
    
    return (
          <span onClick={handleClick} className="theme-toggle" theme={theme} >
            <span className="theme-name">{theme === 1 ? "Light Theme" : (theme === 2 ? "Dark Theme": "System Theme")}</span>
            {savedTheme && theme && theme === 1 ? <span className="material-symbols-rounded">light_mode</span> : (theme === 2 ? <span className="material-symbols-rounded">dark_mode</span> : <span className="material-symbols-rounded">routine</span>)}
          </span>
      
          //<span className="theme-toggle">
          //    <span onClick={handleClickSystem} theme={theme} className={theme === 0 ? "current" : ""}><span className="material-symbols-rounded icon-button">routine</span></span>
          //    <span onClick={handleClickLight} theme={theme} className={theme === 1 ? "current" : ""}><span className="icon-button"><MdLightMode /></span></span>
          //    <span onClick={handleClickDark} theme={theme} className={theme === 2 ? "current" : ""}><span className="icon-button"><MdDarkMode /></span></span>
          //</span>
    );

};

export default ThemeSwitch;

    //<DarkModeSwitch
    //  checked={isDarkMode}
    //  onChange={toggleDarkMode}
    //  size={20}
    //  className={localStorage.getItem("dark-theme") ? "theme-toggle reset" : "theme-toggle change"}
    //  moonColor="#8A8A8B"
    //  sunColor="#8A8A8B"
    ///>


//style={{ marginTop: '2rem', marginLeft: '2rem' }}

//style={{color: "#8A8A8B", fill: "#8A8A8B"}}


//content: '<span>Change Theme <span onClick=`${localStorage.removeItem("dark-theme")};${location.reload()}`  className="material-symbols-rounded" style="font-size: 1em; position: relative; top: 2px; cursor: pointer;">routine</span></span>',


//{initialValue = 0, onChange}

//localStorage.getItem('theme') === null ? 0 : localStorage.getItem('theme')
