import React, { Component, useEffect } from 'react';
import moment from 'moment';
import { Link, useLocation } from 'react-router-dom';

import Footer from './Footer';
import Header from './Header';
import CoffeeButton from './CoffeeButton';

import ReactGA from 'react-ga4';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import 'animate.css';

import { motion, AnimatePresence } from "framer-motion";

import { FaExternalLinkSquareAlt } from "react-icons/fa";
import { FaQuoteLeft } from "react-icons/fa6";
import { IoChatboxEllipses } from "react-icons/io5";

//import { MdSportsMartialArts } from "react-icons/md";
//import { MdOutlineSportsKabaddi } from "react-icons/md";
//import { PiCpuBold } from "react-icons/pi";
//import { MdEmojiEmotions } from "react-icons/md";
//import { BsPostcardHeart } from "react-icons/bs";
//import { BsPostcardHeartFill } from "react-icons/bs";

//const Contact = () => {
export default class About extends Component {
    render() {
    let resumeData = this.props.resumeData;
        
    ReactGA. send ({ 
      hitType: "pageview", 
      page: "/about",
      title: "About",
    });
        
    const url = window.location.href;
    const pieces = url.split("/");
    const page = pieces[pieces.length - 1];
    
    moment().format("MM/DD/YYYY");
    const now = moment(new Date());
    let today = moment().format("MMM Do");
//
    let born = moment('1984-11-12');
    let birthday = today == "Nov 12th";
    let age = now.diff(born, 'years', true);
      
    let work = moment('2007-06-04'); 
    let experience = now.diff(work, 'years', true);
        
    let projects =  resumeData.portfolio.length;
        
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 1
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
    };
        
    const routeVariants = {
        initial: {
            y: '50vh'
        },
        final: {
            y: '0vh',
            transition: {
              type: "spring",
              mass: 0.4,
            },
        }
    };
        
    //const componentMap = {
    //  MdSportsMartialArts,
    //  MdOutlineSportsKabaddi,
    //  PiCpuBold,
    //  MdEmojiEmotions,
    //  BsPostcardHeart,
    //  BsPostcardHeartFill
    //};
    //  
    //function DynamicComponent({ componentName }) {
    //  const Component = componentMap[componentName];
//
    //  if (!Component) {
    //    return <p>Component not found: {componentName}</p>;
    //  }
//
    //  return <Component />;
    //}

    return (
        <div className="container">
        <Header resumeData={resumeData} />
        <motion.div
            variants={routeVariants}
            initial="initial"
            animate="final"
        >
            <section id="page-accent" >
                <div id="page-title" className="row">
                    <h1 className="page-heading">About</h1>
                </div>
            </section>
            <section id="about"> 
                <div className="row content">
                    <div className="one-third">
                        <img className="profile-pic"  src="images/brand/js_cover.webp" alt="Josh Sroufe" />
                        <img className="signature"  src="images/brand/signature.png" alt="Josh Sroufe Signature" />
                    </div>
                    <div className="two-thirds">
                        <p>I'm {resumeData.name}, a {Math.floor(age)}-year-old {resumeData.address} native {resumeData.roleDescription}. With <Link to="/resume" className="highlight">over {Math.floor(experience)} years of experience</Link> in innovative strategy and product design, {resumeData.aboutme} Currently, I lead the design team at {resumeData.work[0].CompanyName}, where we are dedicated to crafting user-centric experiences that truly make a difference.</p>
                        <div className="callouts">
                            <Link to="/contact"><button><span class="material-symbols-rounded">forum</span> Let's connect</button></Link>
                            <CoffeeButton /> 
                        </div>
                    </div>
                </div>
            </section>
            <section id="values">
                <div className="row">
                    <h4>Core values</h4>
                    <ul>
                        {
                        resumeData.values && resumeData.values.map((item)=>{
                            return(
                                <li className="half" key={item.title}>
                                    <span className="value-title"><span>{item.img}</span> <h3>{item.title}</h3></span>
                                    <span>{item.description}</span>
                                </li>
                            )
                        })
                        }
                    </ul>
                </div>
            </section>
            <section id="facts">
                <div className="row">
                    <h4>Interesting facts</h4>
                    <ul>
                        {
                        resumeData.facts && resumeData.facts.map((item)=>{
                            return(
                                <li className="one-third" key={item.title}>
                                    <a href={item.url} target="_blank">
                                        <span className="fact-icon"><span class="material-symbols-rounded">{item.img}</span></span>
                                        <span className="fact-title"><h3>{item.title}</h3></span>
                                        <span className="fact-description">{item.description}</span>
                                    </a>
                                </li>
                            )
                        })
                        }
                    </ul>
                </div>
            </section>
            <section id="recommendations">
                <div className="row">
                    <div className="content">
                        <h4>Testimonials</h4>
                        <Carousel 
                            responsive={responsive}
                            swipeable={true}
                            showDots={true}
                            arrows={false}
                            infinite={true}
                            deviceType={this.props.deviceType}
                            autoPlay={this.props.deviceType !== "mobile" ? true : false}
                            autoPlaySpeed={10000}
                            removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
                            dotListClass="pagination"
                            minimumTouchDrag="50"
                        >
                        {
                        resumeData.testimonials && resumeData.testimonials.map((item)=>{
                            return(
                                <blockquote key={item.name}>
                                    <FaQuoteLeft className="quote-icon" />
                                    <p>{item.description}</p>
                                    <div className="quote-footer">
                                        <img className="quoter" src={item.image} />
                                        <cite>
                                            <span className="name">{item.name}</span>
                                            <span className="title">{item.title}, {item.company}</span>
                                        </cite>
                                    </div>
                                </blockquote>
                            )
                        })
                        }
                        </Carousel>
                    </div>
                </div>
            </section>
        </motion.div>
        <Footer resumeData={resumeData} />
        </div>
    );
  }
}

//export default Contact;

//<Link to="/resume">
//  <div className="freelancing">
//      <span className="metric">{Math.floor(experience)}+</span> Years of Experience
//  </div>
//</Link>
//<Link to="/portfolio">
//  <div className="freelancing">
//      <span className="metric">{Math.floor(projects)}</span> Portfolio Projects
//  </div>
//</Link>

//<div className="experience">
//   <div className="metric">{Math.floor(experience)}+</div>
//   <div className="label">Years of Experience</div>
//</div>

//<Testimonials resumeData={resumeData} />
//
//<div className="columns contact-details">
//  <h2>Contact Details</h2>
//  <p className="address">
//            <span>{resumeData.name}</span>
//     <br></br>
//               <span>
//     {resumeData.address}
//    </span>
//    <br></br>
//    <span>{resumeData.website}</span>
//  </p>
//</div>

//<Footer resumeData={resumeData} />


//<ul className="slides">
//  {
//    resumeData.testimonials && resumeData.testimonials.map((item)=>{
//      return(
//        <li>
//          <blockquote>
//            <p>
//            {item.description}
//            </p>
//            <div className="quote-footer">
//                <img className="quoter" src={item.image} />
//                <cite>
//                    <span className="name">{item.name}</span>
//                    <span className="title">{item.title}, {item.company}</span>
//                </cite>
//            </div>
//          </blockquote>
//        </li>
//      )
//    })
//  }
//</ul>


//animate__animated animate__slideInUp

//<FaExternalLinkSquareAlt />
