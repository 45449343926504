import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Footer from './Footer';
import Header from './Header';

import ReactGA from 'react-ga4';
import Carousel from "react-multi-carousel";
import { motion, AnimatePresence } from "framer-motion";

export default class Portfolio extends Component {
  render() {
    let resumeData = this.props.resumeData;
      
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 6
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
      }
    };
    
    ReactGA. send ({ 
      hitType: "pageview", 
      page: "/portfolio",
      title: "Portfolio",
    });
      
    const routeVariants = {
        initial: {
            y: '50vh'
        },
        final: {
            y: '0vh',
            transition: {
              type: "spring",
              mass: 0.4,
            },
        }
    };
      
    return (
        <div className="container">
        <Header resumeData={resumeData} />
        <motion.div
            variants={routeVariants}
            initial="initial"
            animate="final"
        >
            <section id="portfolio">
                <div id="page-title" className="row">
                    <h1 className="page-heading">Portfolio</h1>
                </div>
                <div className="row">
                    <div className="collapsed">
                        <div id="portfolio-wrapper">
                            {
                            resumeData.portfolio && resumeData.portfolio.map((item)=>{
                                return(
                                    <div className="portfolio-item half" key={item.name}>
                                        <div className="item-wrap">
                                            <Link to={"/" + item.description.replace(/\s+/g, '-').toLowerCase() + "/" + item.name.replace(/\s+/g, '-').toLowerCase()} style={{ backgroundColor: `rgba(${item.accentColor}, 0.2)`, }}>
                                                <img src={item.description === "Case Study" ? item.coverImage : item.items[0].imgurl} className={item.description === "Case Study" ? "item-img reverse" : "item-img" }/>
                                                <div className="overlay">
                                                    <div className="portfolio-item-meta"> 
                                                        <img src={item.logo} className="cover-logo" />
                                                        <div className="meta-text">
                                                            <h2>{item.name}</h2>
                                                            <p>{item.description} &bull; Updated {item.updated}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>
                    </div>
                </div>
            </section>
        </motion.div>
        <Footer resumeData={resumeData} />
        </div>
    );
  }
}

//<Carousel 
//    responsive={responsive}
//    swipeable={true}
//    showDots={false}
//    arrows={true}
//    infinite={true}
//    deviceType={this.props.deviceType}
//    dotListClass="pagination"
//    minimumTouchDrag="50"
//>
//{
//resumeData.portfolio && resumeData.portfolio.map((item)=>{
//  return(
//    <div className="portfolio-item" key={item.name}>
//          {/*<a href="#modal-01">*/}
//          <img src={item.imgurl} className="item-img"/>
//          <div className="overlay">
//            <div className="portfolio-item-meta">
//              <h5>{item.name}</h5>
//              <p>{item.description}</p>
//            </div>
//          </div>
//        {/*</a>*/}
//    </div>
//  )
//})
//}
//</Carousel>