import moment from 'moment';

export const fetchWeatherData = async (latitude, longitude) => {
  const appid = "d1e0d0fc24d0487556c22f12ddf15120";
  const url = `https://api.openweathermap.org/data/2.5/weather?lat=${latitude}&lon=${longitude}&appid=${appid}&units=imperial`;
  let moment = require('moment-timezone');

  try {
    const response = await fetch(url);
    const data = await response.json();
    let timezone = "Etc/Universal";

    if (data.cod === "200" || data.cod === 200) {
      // Store for caching if needed
      localStorage.setItem("weather", JSON.stringify(data));
      localStorage.setItem("weather_issued", new Date());

      console.log('Weather check complete', moment().format('M/D/y h:mm:ss.SSS a'));

      const getZoneFromOffset = (offsetString) => {
        return moment.tz.names().filter(tz => moment.tz(tz).format('Z') === offsetString);
      };

      let tzFormat = data.timezone / 36;
      tzFormat = tzFormat.toString();

      if (tzFormat.slice(0,1) === "-") {
        if (tzFormat.length === 4) {
          tzFormat = tzFormat.slice(0,1) + 0 + tzFormat.slice(1,2) + ":" + tzFormat.slice(2);
        }
        else {
          tzFormat = tzFormat.slice(0,1) + tzFormat.slice(1,3) + ":" + tzFormat.slice(3);
        }
      }
      else {
        if (tzFormat.length === 4) {
          tzFormat = "+" + tzFormat.slice(0,1) + tzFormat.slice(1,2) + ":" + tzFormat.slice(2);
        }
        else {
          tzFormat = "+0" +  tzFormat.slice(0,1) + ":" + tzFormat.slice(2) + "0";
        }
      }

      if (data.timezone !== 0 && data.timezone !== "0"){
          timezone = getZoneFromOffset(tzFormat)[0];
      }

      return {
        data,
        weatherIssued: new Date(),
        localOffset: tzFormat,
        timezone: timezone
      };
    } else {
      console.warn("Weather fetch failed:", data);
      return null;
    }
  } catch (err) {
    console.error("Error fetching weather:", err);
    return null;
  }
};
