import React, { Component, useEffect, useState, useRef } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Link,
} from "react-router-dom";
import { useLocation } from "react-router-dom";

import Home from './components/Home';
import About from './components/About';
import Resume from './components/Resume';
import Portfolio from './components/Portfolio';
import PortfolioProject from './components/PortfolioProject';
import PortfolioStudy from './components/PortfolioStudy';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Blog from './components/Blog';
import BlogPost from './components/BlogPost';
import PageNotFound from './components/PageNotFound';
import OneSignalInit from './components/OneSignal';
import ScrollToTop from './components/ScrollToTop';
import ConfettiTime from './components/ConfettiTime';
import ThemeSwitch from './components/ThemeSwitch';
import CoffeeWidget from './components/CoffeeWidget';
//import Schedule from './components/Schedule';

import { motion, AnimatePresence } from "framer-motion";
import ReactGA from 'react-ga4';

import resumeData from './resumeData';

function App(props) {
    
    useEffect (() => {
        ReactGA.initialize('G-EQ1Q3X4DJV');
    }, []);

    function handleRefresh() { window.location.reload(true); }
  
    //const handleRefresh = async () => {
    //  //setIsRefreshing(true);
//
    //  // Fetch your data here
    //  //const newData = await fetchData();
    //  //setData(newData);
    //  //setRefreshing(false);
    //  
    //  window.location.reload(true);
    //};
    
    function usePullToRefresh(onRefresh) {
      const [isRefreshing, setIsRefreshing] = useState(false);
      const startY = useRef(0);

      const handleTouchStart = (e) => {
        startY.current = e.touches[0].clientY;
      };

      const handleTouchMove = (e) => {
        if (isRefreshing) return;

        const currentY = e.touches[0].clientY;
        const distance = currentY - startY.current;

        if (distance > 0 && window.scrollY === 0) {
          // Pull down detected
          e.preventDefault(); // Prevent default scrolling behavior
          // Add logic to display a loading indicator
        }
      };

      const handleTouchEnd = () => {
        if (isRefreshing) return;

        // Check if the pull distance is enough to trigger refresh
        if (window.scrollY < -24) {
          setIsRefreshing(true);
          onRefresh().finally(() => setIsRefreshing(false));
        }
      };

      useEffect(() => {
        window.addEventListener('touchstart', handleTouchStart);
        window.addEventListener('touchmove', handleTouchMove);
        window.addEventListener('touchend', handleTouchEnd);

        return () => {
          window.removeEventListener('touchstart', handleTouchStart);
          window.removeEventListener('touchmove', handleTouchMove);
          window.removeEventListener('touchend', handleTouchEnd);
        };
      }, [isRefreshing, onRefresh]);

      return isRefreshing;
    }
  
    const isRefreshing = usePullToRefresh(handleRefresh);
  
    function LocationProvider() {  return <AnimatePresence></AnimatePresence>; }
    
    function LocationProvider({ children }) {
      return <AnimatePresence>{children}</AnimatePresence>;
    }

    //const [theme, setTheme] = useState(props.theme);
    const savedTheme = localStorage.getItem('theme');

    function RoutesWithAnimation() {
      const location = useLocation(); 

      return (
          <Routes location={location} key={location.key}>
              <Route path="/about" element={<About resumeData={resumeData} />} />
              <Route path="/resume" element={<Resume resumeData={resumeData} />} />
              <Route path="/portfolio" element={<Portfolio resumeData={resumeData} />} />
              <Route path="/projects" element={<PortfolioProject resumeData={resumeData} />} />
              <Route path="/projects/pulse-labs" element={<PortfolioProject resumeData={resumeData} />} />
              <Route path="/projects/house-canary" element={<PortfolioProject resumeData={resumeData} />} />
              <Route path="/projects/clear-capital" element={<PortfolioProject resumeData={resumeData} />} />
              <Route path="/projects/apple" element={<PortfolioProject resumeData={resumeData} />} />
              <Route path="/projects/miscellaneous" element={<PortfolioProject resumeData={resumeData} />} />
              <Route path="/case-study/aegis-smart-home" element={<PortfolioStudy resumeData={resumeData} />} />
              <Route path="/blog" element={<Blog resumeData={resumeData} />} />
              <Route path="/blog-post" element={<BlogPost resumeData={resumeData} />} />
              <Route path="/contact" element={<Contact resumeData={resumeData} />} />
              <Route path="*" element={<Home resumeData={resumeData} />} />
          </Routes>
      );
    }

    //function isiPadStandalone() {
    //    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    //    const isStandalone = window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone;
//
    //    return isIOS && isStandalone && navigator.platform === 'iPad';
    //}
//
    //if (isiPadStandalone()) {
    //    console.log("Running on an iPad in standalone mode!");
    //    // Add your custom logic for iPad standalone mode here
//
    //    const header = document.document.getElementById('header');
//
    //    header.style.paddingTop = "80px";
    //}
      
    return (
      <div id="theme" className="App dark" theme={props.theme}>
            <ConfettiTime />
            <BrowserRouter>
                <LocationProvider>
                    <ScrollToTop />
                    <RoutesWithAnimation />
                    <ThemeSwitch />
                </LocationProvider>
            </BrowserRouter>
      {isRefreshing && <div>Refreshing...</div>}
      </div>
    );

}

export default App;

//<OneSignalInit />

//<Route path="/app" element={<Schedule resumeData={resumeData} />} />

//<CoffeeWidget theme={props.theme} />
              
//<Header resumeData={resumeData}/>
//<About resumeData={resumeData}/>
//<ContactUs resumeData={resumeData}/>
//<Footer resumeData={resumeData}/>
//<Resume resumeData={resumeData}/>
//<Testimonials resumeData={resumeData}/>
//<Portfolio resumeData={resumeData}/>

//import {
//  BrowserRouter,
//  Routes,
//  Route,
//  Link,
//} from "react-router-dom";
//<Route path="/live" element={<Live />} />

//<PullToRefresh onRefresh={handleRefresh}>

      //<PullToRefresh onRefresh={handleRefresh} isRefreshing={refreshing}>
