import React, { Component } from 'react';
import { TypeAnimation } from 'react-type-animation';
import { Link } from 'react-router-dom';

import Social from './Social';
import Nav from './Nav';
import Availability from './Availability';
import Weather from './Weather';

import ReactGA from 'react-ga4';
import { motion, AnimatePresence } from "framer-motion";

export default class Home extends Component {

  render() {
    let resumeData = this.props.resumeData; 
      
    ReactGA. send ({ 
      hitType: "pageview", 
      page: "/",
      title: "Home",
    });
      
    const routeVariants = {
        initial: {
            y: '-100vh'
        },
        final: {
            y: '0vh',
            transition: {
              type: "spring",
              mass: 0.4,
            },
        }
    };
      
    //let roles = 
    //resumeData.role && resumeData.role.map(item =>{
    //    
    //    return(
    //        "'" + item.title + "', " + Number(item.interval) + "," 
    //        //item.title
    //        //item.interval
    //    );
    //});
    //
    ////let roleStrings = 
    ////roles && roles.forEach((element) => {
    ////    
    ////    return(
    ////        element + ","
    ////    );
    ////});
    //
    //let roleStrings = roles.forEach((element) => {return(element)});
    //  
    ////rolesArray.forEach((element) => element)
    //
    ////roleStrings = roles.toString();
    //
    //console.log(roleStrings);
    
    

      return (
        <motion.div
            variants={routeVariants}
            initial="initial"
            animate="final">
            <div id="home">
                <div className="row banner">
                    <video id="background-video" autoPlay loop={true} muted={true} playsInline>
                        <source src={resumeData.videos[0].url} type="video/mp4" />
                    </video>
                    <div className="banner-text">
                        <section className="main">
                            <img className="logo"  src={resumeData.logos[0].url} alt=""/>
                            <h1>{resumeData.name}</h1>
                            <h3 style={{color:'#fff', fontFamily:'sans-serif '}}>
                                <TypeAnimation
                                  //sequence={[roleStrings]}
                                  sequence={['Entrepreneur', 2000, 'Design Director', 2000, 'Husband & Father', 2000, 'Mixed Martial Artist', 2000]}
                                  style={{ fontSize: '2em', fontFamily: 'Open Sans'}}
                                  repeat={Infinity}
                                  cursor={false}
                                />
                            </h3>
                        </section>
                        <section id="apple-map">
                            <div className="row">
                                <Weather lat={resumeData.lat} long={resumeData.long} />
                            </div>
                        </section>
                        <section className="links">
                            <Nav />
                            <Social resumeData={resumeData} />
                            <Availability resumeData={resumeData} />
                        </section>
                    </div>
                </div>
            </div>
        </motion.div>
    );
  }
}

//<p>{resumeData.roleDescription}</p>

//resumeData.role

//<nav id="nav-wrap">
//    <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
//    <a className="mobile-btn" href="#" title="Hide navigation">Hide navigation</a>
//    <ul id="nav" className="nav">
//       <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
//       <li><a className="smoothscroll" href="#about">About</a></li>
//       <li><a className="smoothscroll" href="#resume">Resume</a></li>
//       <li><a className="smoothscroll" href="#portfolio">Works</a></li>
//       <li><a className="smoothscroll" href="#testimonials">Testimonials</a></li>
//       <li><a className="smoothscroll" href="#contact">Contact</a></li>
//    </ul>
//</nav>
